<template>
  <mp-box
    position="relative"
    :padding-right="!isToggle ? '4' : '0px'"
    background-color="background"
    transition="all 600ms cubic-bezier(0.4, 0, 0.2, 1) 0s"
  >
    <mp-box
      position="relative"
      background-color="background"
      transition="all 600ms cubic-bezier(0.4, 0, 0.2, 1) 0s"
    >
      <mp-box
        id="section-sidebar-child"
        as="section"
        data-id="sidebar-child"
        max-width="12.75rem"
        :width="isToggle ? '12.75rem' : '0'"
        height="calc(100vh - 60px)"
        padding-y="2"
        :padding-x="isToggle ? '2' : '2'"
        margin-left="60px"
        :cursor="isToggle ? 'default' : 'pointer'"
        :background-color="!isToggle && isHovered ? 'ash.100' : 'background'"
        transition="all 600ms cubic-bezier(0.4, 0, 0.2, 1) 0s"
        :border-right="isToggle ? '0px' : '1px'"
        border-color="gray.100"
        :display="['block']"
        z-index="1"
        @mouseenter="handleMouseEnter()"
        @mouseleave="handleMouseLeave()"
        @click.self="handleToggleBlock"
      >
        <mp-box
          max-height="calc(100vh - 90px - 52px)"
          max-width="188px"
          width="inherit"
          overflow-y="auto"
          overflow-x="hidden"
        >
          <mp-text
            text-transform="uppercase"
            white-space="nowrap"
            font-size="sm"
            font-weight="semibold"
            letter-spacing="2px"
            color="blue.400"
            mt="3"
            mb="1"
            padding="2"
          >
            {{ currentPage }}
          </mp-text>

          <mp-accordion allow-toggle>
            <mp-box
              v-for="(submenu, index) in submenuFiltered"
              :key="submenu.name"
            >
              <!-- Submenu without child -->
              <mp-box v-if="submenu.child.length < 1">
                <mp-flex direction="column">
                  <mp-pseudo-box
                    flex="1"
                    role="group"
                    border-radius="md"
                    padding="2"
                    :background-color="
                      submenu.isActive ? 'blue.100' : 'transparent'
                    "
                    transition="all 600ms cubic-bezier(0.4, 0, 0.2, 1) 0s"
                    :_hover="{
                      color: 'blue.400',
                      backgroundColor: 'blue.100',
                      cursor: 'pointer',
                    }"
                    @click="
                      onSubmenuClick(submenu, index, submenu.child.length > 0)
                    "
                  >
                    <mp-stack
                      direction="row"
                      align="center"
                      justify="space-between"
                    >
                      <mp-text
                        white-space="nowrap"
                        :color="submenu.isActive ? 'blue.400' : 'dark'"
                        :font-weight="submenu.isActive ? 'semibold' : 'regular'"
                      >
                        {{ submenu.name }}
                      </mp-text>
                      <mp-badge
                        v-if="
                          submenu.name === 'Export' ||
                          submenu.name === 'Chatbot AI' ||
                          submenu.name === 'Google My Business' ||
                          submenu.isNew
                        "
                        variant="solid"
                        variant-color="red"
                        size="sm"
                      >
                        NEW
                      </mp-badge>
                    </mp-stack>
                  </mp-pseudo-box>
                </mp-flex>
              </mp-box>
              <!-- Submenu that have childs -->
              <mp-box v-else>
                <mp-accordion-item
                  border-bottom-width="0px!important"
                  :is-open="accordionIdx === index"
                  @change="
                    onSubmenuClick(submenu, index, submenu.child.length > 0)
                  "
                >
                  <mp-accordion-header
                    gap="1"
                    padding="2"
                    border-radius="md"
                    :background-color="
                      submenu.isActive ? 'blue.100' : 'transparent'
                    "
                    transition="all 600ms cubic-bezier(0.4, 0, 0.2, 1) 0s"
                    :_hover="{
                      color: 'blue.400',
                      backgroundColor: submenu.isActive
                        ? 'blue.100'
                        : 'blue.100',
                      cursor: 'pointer',
                    }"
                  >
                    <mp-flex justify-content="space-between" width="100%">
                      <mp-box>
                        <mp-flex direction="column">
                          <mp-pseudo-box flex="1" border-radius="sm">
                            <mp-stack direction="row" align="center">
                              <mp-text
                                white-space="nowrap"
                                :color="submenu.isActive ? 'blue.400' : 'dark'"
                                :font-weight="
                                  submenu.isActive ? 'semibold' : 'regular'
                                "
                              >
                                {{ submenu.name }}
                              </mp-text>
                            </mp-stack>
                          </mp-pseudo-box>
                        </mp-flex>
                      </mp-box>
                      <mp-accordion-icon />
                    </mp-flex>
                  </mp-accordion-header>
                  <mp-accordion-panel padding-y="0">
                    <mp-box v-for="child in submenu.child" :key="child.code">
                      <mp-flex
                        v-if="child.status"
                        direction="column"
                        @click="onSubmenuChildClick(child)"
                      >
                        <mp-pseudo-box
                          flex="1"
                          role="group"
                          border-radius="md"
                          padding-left="4"
                          padding-y="2"
                          color="dark"
                          transition="all 600ms cubic-bezier(0.4, 0, 0.2, 1) 0s"
                          :_hover="{
                            color: 'blue.400',
                            cursor: 'pointer',
                          }"
                        >
                          <mp-stack
                            direction="row"
                            align="center"
                            justify="space-between"
                          >
                            <mp-text
                              white-space="nowrap"
                              :font-weight="child.isActive ? 'semibold' : ''"
                              :color="child.isActive ? 'dark' : 'gray.600'"
                            >
                              {{ child.title }}
                            </mp-text>
                            <mp-badge
                              v-if="child.isNew"
                              variant="solid"
                              variant-color="red"
                              size="sm"
                            >
                              NEW
                            </mp-badge>
                          </mp-stack>
                        </mp-pseudo-box>
                      </mp-flex>
                    </mp-box>
                  </mp-accordion-panel>
                </mp-accordion-item>
              </mp-box>
            </mp-box>
          </mp-accordion>

          <!-- // BUTTON COLLAPSE SIDEBAR CHILD -->
          <mp-flex
            v-if="isToggle"
            gap="2"
            background-color="background"
            flex-direction="row"
            justify-content="flex-end"
            align-items="center"
            position="fixed"
            bottom="0"
            :left="isStacked ? '12.75rem' : '60px'"
            padding="2"
            width="inherit"
          >
            <mp-tooltip
              position="right"
              :show-delay="200"
              label="Click to collapse or shortcut [shift] + [c]"
              use-portal
            >
              <mp-pseudo-box
                role="group"
                border-radius="sm"
                transition="all 600ms cubic-bezier(0.4, 0, 0.2, 1) 0s"
                padding-x="2.5"
                padding-y="2"
                outline="none !important"
                :_hover="{
                  backgroundColor: 'blue.100',
                  color: 'blue.400',
                  cursor: 'pointer',
                }"
                @click="handleToggle"
              >
                <mp-stack direction="row" align="center">
                  <mp-icon name="chevrons-previous" size="sm" />
                </mp-stack>
              </mp-pseudo-box>
            </mp-tooltip>
          </mp-flex>
        </mp-box>
      </mp-box>
    </mp-box>
    <mp-box
      position="fixed"
      width="inherit"
      bottom="8px"
      :left="isStacked ? '14.50rem' : '76px'"
      z-index="1"
      :opacity="!isToggle ? '1' : '0'"
      transition-property="all"
      transition-duration="300ms"
      :transition-delay="!isToggle ? '300ms' : ''"
      transition-timing-function="cubic-bezier(.4,0,.2,1)"
      :transform="!isToggle ? 'translateX(0px);' : 'translateX(-8px);'"
    >
      <mp-tooltip
        position="right"
        :show-delay="200"
        label="Click to expand or shortcut [shift] + [c]"
        use-portal
      >
        <mp-pseudo-box
          display="flex"
          :width="isHovered ? '36px' : '24px'"
          height="36px"
          background-color="white"
          border="1px"
          border-color="gray.100"
          border-top-right-radius="full"
          border-bottom-right-radius="full"
          align-items="center"
          justify-content="flex-end"
          padding-right="1"
          padding-left="1"
          box-shadow="rgb(0 0 0 / 14%) 3px 0px 4px, rgb(0 0 0 / 12%) 0px 0px 2px"
          transition="all .3s cubic-bezier(.4,0,.2,1)"
          :_hover="{
            width: '36px',
            cursor: 'pointer',
          }"
          @mouseenter="handleMouseEnter()"
          @mouseleave="handleMouseLeave()"
          @click="handleToggle"
        >
          <mp-icon name="chevrons-right" size="sm" />
        </mp-pseudo-box>
      </mp-tooltip>
    </mp-box>
  </mp-box>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'SidebarChild',
  props: {
    menus: {
      type: [Array, Object],
      default: () => [],
    },
    submenus: {
      type: [Array, Object],
      default: () => [],
    },
    isStacked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isToggle: true,
      isHovered: false,
      isActive: true,
      accordionIdx: -1,
    }
  },
  computed: {
    billingInfo() {
      return this.$store.getters['preferences/getBillingInfo']
    },
    aireneInfo() {
      return this.$store.getters['organization/getSmartAssistInfo']
    },
    permissionsInfo() {
      return this.$store.getters['organization/getPermissions']
    },
    billingPostpaidStatus() {
      // if billing status === postpaid
      return (
        this.billingInfo.billing_enabled === true &&
        this.billingInfo.payment_type === 'prepaid' &&
        !this.billingInfo.is_trial
      )
    },
    slaManagementInfo() {
      return this.billingInfo.sla_dashboard_enabled === true
    },
    submenuFiltered() {
      let submenu = this.submenus
      if (
        !(
          this.aireneInfo ||
          this.permissionsInfo.chatbot_tone_of_voice ||
          this.permissions.chatbot_rollout_package
        )
      ) {
        submenu = submenu.filter((menu) => menu.name !== 'Mekari Airene')
      }
      if (!this.permissionsInfo.chatbot_score_card) {
        submenu = submenu.filter((menu) => menu.name !== 'Scorecard')
      }
      if (!this.billingPostpaidStatus)
        submenu = submenu.filter((menu) => menu.name !== 'Subscription')

      if (!this.slaManagementInfo)
        submenu = submenu.filter((menu) => menu.name !== 'SLA management')
      return submenu
    },
    currentPage() {
      const [firstName] = this.$route.name.split('-')
      const selectedMenu = _.filter(
        this.menus,
        function (data) {
          return data.link === `/${this.existingPage}`
        }.bind({ existingPage: firstName })
      )
      return selectedMenu ? selectedMenu[0]?.name : ''
    },
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeydown)
    this.setDefaultSubmenuOpen()
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeydown)
  },
  methods: {
    handleToggle() {
      this.isToggle = !this.isToggle
      this.$emit('toggle', this.isToggle)
    },
    handleToggleBlock() {
      if (!this.isToggle) this.isToggle = true
      this.$emit('toggle', this.isToggle)
    },
    handleMouseEnter() {
      this.isHovered = true
    },
    handleMouseLeave() {
      this.isHovered = false
    },
    handleKeydown(e) {
      const SHIFTKEY = e.shiftKey
      const KEYCODE_C = e.keyCode === 67
      if (SHIFTKEY && KEYCODE_C) {
        this.handleToggle()
      }
    },
    submenuIsOpened(submenu) {
      let isOpen = false
      submenu.child.forEach((child) => {
        if (child.isActive) isOpen = true
      })
      return isOpen
    },
    onSubmenuClick(submenu, index, hasChild) {
      if (index === this.accordionIdx && hasChild) index = -1
      this.accordionIdx = index
      if (!hasChild) this.$router.push(submenu.link)
      if (submenu.isTracked) {
        this.storeMixpanelMetric(`Broadcast Report ${submenu.title} Page`, {
          Role: this.$auth.user.role,
          'User ID': this.$auth.user.id,
          'Company ID': this.getOrganizationForMetric()['Company ID'],
        })
      }
    },
    accordionOpenedCheck(index, hasChild) {
      return index === this.accordionIdx && hasChild
    },
    setDefaultSubmenuOpen() {
      const activeSubmenu = this.submenus.filter((submenu) => {
        return submenu.isActive
      })
      if (activeSubmenu.length > 0) this.accordionIdx = activeSubmenu[0].index
    },
    onSubmenuChildClick(child) {
      this.$router.push(`/${child.slug}`)
      if (child.isTracked) {
        this.storeMixpanelMetric(`Broadcast Report ${child.title} Page`, {
          Role: this.$auth.user.role,
          'User ID': this.$auth.user.id,
          'Company ID': this.getOrganizationForMetric()['Company ID'],
        })
      }
    },
  },
}
</script>
